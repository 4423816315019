import "swiper/css";
import { Col, Row } from "react-bootstrap";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/swiper-bundle.css";
import "./style.css";

const Slider = () => {
      const data = [
            {
                  images: [
                        "/assets/logo.png",
                        "/assets/logo.png",
                        "/assets/logo.png",
                        "/assets/logo.png",
                        "/assets/logo.png",
                  ],
            },
      ];

      return (
            <div className="logo__wrapper">
                  <Swiper
                        slidesPerView={1}
                        spaceBetween={10}
                        autoplay={{
                              delay: 1000,
                              disableOnInteraction: false,
                        }}
                        loop={true}
                        breakpoints={{
                              640: {
                                    slidesPerView: 2,
                                    spaceBetween: 20,
                              },
                              768: {
                                    slidesPerView: 3,
                                    spaceBetween: 20,
                              },
                              1024: {
                                    slidesPerView: 4,
                                    spaceBetween: 20,
                              },
                        }}
                        className="mySwiper"
                        modules={[Autoplay, Pagination, Navigation]}
                  >
                        <Row>
                              <Col md="4">
                                    <div className="logo__slider">
                                          {data[0].images.map(
                                                (image, index) => (
                                                      <SwiperSlide key={index}>
                                                            <img
                                                                  src={image}
                                                                  alt={`slide-${index}`}
                                                            />
                                                      </SwiperSlide>
                                                )
                                          )}
                                    </div>
                              </Col>
                        </Row>
                  </Swiper>
            </div>
      );
};

export default Slider;
