import React, { useEffect } from "react";
import "./style.css";
import {
      FacebookIcon,
      LocationIcon,
      CallIcon,
      MailIcon,
} from "../components/svg";
import { Col, Row, Container } from "react-bootstrap";
import Slider from "./components/Slider";
import QuickMenu from "./components/QuickMenu";
const LandingPage = () => {
      useEffect(() => {
            const landingImg = document.getElementById("landing-img");

            if (landingImg) {
                  landingImg.classList.add("transitioned");
            }
      });

      return (
            <div className="landing-page">
                  <div className="blue-overlay"></div>
                  <div className="landing__header">
                        <Container>
                              <div className="landing__logo">
                                    <div className="header__logo">
                                          <img
                                                src="/assets/plumber_logo.png"
                                                alt="Plumber Official Logo"
                                                className="img-fluid"
                                          />
                                    </div>
                                    <div className="header__social">
                                          <div className="nav-content">
                                                <span style={{ "--i": 4 }}>
                                                      <a
                                                            href="https://www.facebook.com/PlumberPipesNepal"
                                                            target="_blank"
                                                      >
                                                            <i className="bx bxl-facebook"></i>
                                                      </a>
                                                </span>
                                                <span style={{ "--i": 3 }}>
                                                      <a
                                                            href="https://www.instagram.com/plumberpipes"
                                                            target="_blank"
                                                      >
                                                            <i class="bx bxl-instagram"></i>
                                                      </a>
                                                </span>
                                                <span style={{ "--i": 2 }}>
                                                      <a
                                                            href="https://www.linkedin.com/company/plumberpipes"
                                                            target="_blank"
                                                      >
                                                            <i class="bx bxl-linkedin"></i>
                                                      </a>
                                                </span>
                                          </div>
                                    </div>
                              </div>
                        </Container>
                  </div>
                  <div className="landing__section">
                        <Container>
                              <Row>
                                    <Col md="6">
                                          <div className="landing__right">
                                                <Row>
                                                      <Col md="12">
                                                            <div className="landing__body">
                                                                  <div className="landing__widget">
                                                                        <span>
                                                                              QUALITY
                                                                              SERVICES
                                                                        </span>
                                                                  </div>
                                                                  <div className="landing__heading--main">
                                                                        <h1 className="landing__heading">
                                                                              WE
                                                                              ARE
                                                                              COMING
                                                                              SOON
                                                                        </h1>
                                                                  </div>
                                                                  <div className="coming-soon__overlay">
                                                                        COMING
                                                                        SOON
                                                                  </div>
                                                                  <div className="landing__content">
                                                                        <p>
                                                                              Our
                                                                              exciting
                                                                              new
                                                                              website
                                                                              is
                                                                              coming
                                                                              soon!
                                                                              Check
                                                                              back
                                                                              later...
                                                                        </p>
                                                                        <p className="text-muted">
                                                                              <LocationIcon />
                                                                              Laxmi
                                                                              Bank
                                                                              Building,
                                                                              1st
                                                                              Floor,
                                                                              Tripureshwar,
                                                                              Kathmandu,
                                                                              Nepal
                                                                        </p>
                                                                        <p className="text-muted">
                                                                              <CallIcon />
                                                                              01-4261925
                                                                        </p>
                                                                        <p className="text-muted">
                                                                              <MailIcon />
                                                                              info@plumberpipes.com
                                                                        </p>
                                                                  </div>
                                                            </div>
                                                      </Col>
                                                </Row>
                                          </div>
                                    </Col>

                                    <Col md="6">
                                          <div className="landing__img--section">
                                                <div
                                                      className="landing__img"
                                                      style={{
                                                            backgroundImage:
                                                                  "url('/assets/Group 5.png')",
                                                      }}
                                                ></div>
                                          </div>
                                    </Col>
                              </Row>
                        </Container>
                  </div>
                  <div className="landing__footer">
                        <Container>
                              <p className="coming-soon__content">
                                    Powered by
                                    <span>
                                          <a
                                                target="_blank"
                                                href="https://infinityinfosys.com/"
                                          >
                                                {" "}
                                                Infinity Infosys{" "}
                                          </a>
                                    </span>
                              </p>
                        </Container>
                  </div>
            </div>
      );
};

export default LandingPage;
