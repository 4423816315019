import React from 'react'
import LandingPage from '../LandingPage'

const App = () => {
  return (
    <>
      <LandingPage/>
    </>
  )
}

export default App
